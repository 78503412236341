import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogWebRefundTicketComponent } from 'app/dialog/dialog-web-refund-ticket/dialog-web-refund-ticket.component';
import { Common } from 'app/model/entity/common';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Orders } from 'app/model/entity/ticket-editor/orders';
import { CommonService } from 'app/service/common.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../service/dialog.service';
import { Order } from './../../../model/entity/ticket-editor/order';

@Component({
  selector: 'tab-sales-record',
  templateUrl: './tab-sales-record.component.html',
  styleUrls: ['./tab-sales-record.component.scss']
})
export class TabSalesRecordComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;

  Helper = Helper;

  /**
   * language key
   */
  languageKey: string;
  /**
   * Config for datepicker
   */
  config: any;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  placeholderSearch: string; // Set text placeholder input search
  titleHeader = [
    'ticket-manager.sales-record.order-ID',
    'ticket-manager.sales-record.sale-ticket-id',
    'ticket-manager.sales-record.ticket-type',
    'ticket-manager.sales-record.ticket-name',
    'ticket-manager.sales-record.company-name',
    'ticket-manager.sales-record.purchased-at',
    'ticket-manager.sales-record.status',
    'ticket-manager.sales-record.sales-amount'
  ]; // List header ticket filter
  tickets = []; // List ticket filter
  // Common object
  private commonObject: Common;
  /**
   * Column data for ticket information
   */
  columns = ['orderId', 'saleTicketId', 'ticketType', 'ticketName', 'companyName', 'purchasedAt', 'status', 'salesAmount'];
  listTicketType = [];
  listAppID = [];
  isSearching = false; // Status searching
  dataFilter = []; // List ticket after filter
  /**
   * Data for search sales ticket
   */
  dataSearch = {
    dateFrom: '',
    dateTo: '',
    ticketType: '',
    appId: '',
    orderId: ''
  };
  orderSelected: Orders;
  orderDetailSelected: Order;
  comboItemSelected: any;
  dataFilterOrder: Orders[];

  headerColumnOrderDetail1: any = [
    {
      label: 'ticket-manager.sales-record.order-ID',
      field: 'orderId'
    },
    {
      label: 'ticket-manager.sales-record.sale-ticket-id',
      field: 'saleTicketId'
    },
    {
      label: 'ticket-manager.sales-record.ticket-type',
      field: 'ticketType'
    },
    {
      label: 'ticket-manager.sales-record.ticket-name',
      field: 'ticketName'
    },
    {
      label: 'ticket-manager.sales-record.company-name',
      field: 'companyName'
    },
    {
      label: 'ticket-manager.sales-record.user-id',
      field: 'userId'
    },
    {
      label: 'ticket-manager.sales-record.payment-id',
      field: 'paymentId'
    },
    {
      label: 'ticket-manager.sales-record.purchased-at',
      field: 'purchasedAt'
    },
    {
      label: 'ticket-manager.sales-record.status',
      field: 'status'
    },
    {
      label: 'ticket-manager.sales-record.sale-amount',
      field: 'salesAmount'
    },
    {
      label: 'ticket-manager.sales-record.price-adult',
      field: 'priceAdult'
    }
  ];
  headerColumnOrderDetail2: any = [
    {
      label: 'ticket-manager.sales-record.number-adults',
      field: 'numberAdults'
    },
    {
      label: 'ticket-manager.sales-record.number-custom1',
      field: 'numberCustom1'
    },
    {
      label: 'ticket-manager.sales-record.number-custom2',
      field: 'numberCustom2'
    },
    {
      label: 'ticket-manager.sales-record.number-custom3',
      field: 'numberCustom3'
    },
    {
      label: 'ticket-manager.sales-record.refund-deadline-at',
      field: 'refundDeadlineAt'
    },
    {
      label: 'ticket-manager.sales-record.refunded-at',
      field: 'refundedAt'
    }
  ];

  @Input() listApp: ApplicationDTO[]; // list Application
  appIdSelected: string;
  constructor(
    private translateService: TranslateService,
    private ticketManagerService: TicketManagerService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) {
    // Translate title
    this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
    // Action menu
    this.subscriptions.push(
      // Export list ticket
      this.menuActionService.actionExportTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          // Nothing
        }
      }),
      // Refund web
      this.menuActionService.actionRefundTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.webRefund();
        }
      }),
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.clearSearch();
        }
      }),
      // Get language
      this.translateService.onLangChange.subscribe(() => {
        // Translate title
        this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * Search ticket
   * @param item
   */
  searchTicket(): void {
    this.isSearching = true;
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.dataFilterOrder.forEach(item => {
        item.isChecked = false;
      });
    }
    // Clear search
    if (!this.dataSearch['ticketType'] && !this.dataSearch['dateFrom'] && !this.dataSearch['dateTo'] && !this.dataSearch['orderId']) {
      this.clearSearch();
      return;
    }
    let payload = {
      orderId: this.dataSearch['orderId'],
      ticketType: this.dataSearch['ticketType'],
      appId: this.appIdSelected ? (this.appIdSelected == '-1' ? '' : this.appIdSelected) : '',
      startDate: this.dataSearch['dateFrom'] ?? '',
      endDate: this.dataSearch['dateTo'] ?? ''
    };
    this.ticketManagerService.getOrders(payload).subscribe(data => {
      this.dataFilterOrder = data;
      if (!this.dataFilterOrder || this.dataFilterOrder.length == 0) {
        this.orderDetailSelected = undefined;
        return;
      }
      this.selectOrder(this.dataFilterOrder[0], null);
    });
  }

  /**
   * Web refund
   */
  webRefund(): void {
    if (!this.dataFilterOrder) {
      return;
    }
    // Check selected
    let dataChecked = this.dataFilterOrder.filter(item => item.isChecked);
    if (!dataChecked.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.sales-record.choose-ticket')
        }
      });
      return;
    }

    if (dataChecked.length > 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.sales-record.only-choose-one')
        }
      });
      return;
    }

    // Show dialog web refund
    this.dialogService.showDialog(
      DialogWebRefundTicketComponent,
      {
        data: {
          listOrderId: dataChecked[0].orderId,
          salesAmount: dataChecked[0].salesAmount
        }
      },
      result => {
        // nothing
      }
    );
  }

  /**
   * Clear search
   */
  clearSearch() {
    this.isSearching = false;
    this.dataSearch['dateFrom'] = undefined;
    this.dataSearch['dateTo'] = undefined;
    this.dataSearch['ticketType'] = '';
    this.appIdSelected = '-1';
    this.orderSelected = undefined;
    this.orderDetailSelected = undefined;
    this.comboItemSelected = undefined;
  }

  /**
   * selectOrder
   * @param order
   * @param e
   * @returns
   */
  selectOrder(order: Orders, e: any): void {
    if (e?.target?.id === 'checkBoxOrder') {
      return;
    }
    this.orderSelected = order;
    this.ticketManagerService.getOrder(this.orderSelected.orderId).subscribe(data => {
      this.orderSelected.orderDetail = data;
      this.orderDetailSelected = this.orderSelected.orderDetail;
      this.comboItemSelected = data?.comboItems;
    });
  }

  /**
   * changeChecked
   * @param idOrder
   * @param e
   */
  changeChecked(idOrder: string, e): void {
    e.stopPropagation();
    const index = this.dataFilterOrder.findIndex(element => element.orderId == idOrder);
    this.dataFilterOrder[index].isChecked = !this.dataFilterOrder[index].isChecked;
  }

  /**
   * getNameTicketType
   * @param ticketType
   * @returns
   */
  getNameTicketType(ticketType: string) {
    if (ticketType == Constant.SPECIAL_TICKET_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.tickets-according-to-plan');
    } else if (ticketType == Constant.COUPON_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.number-of-tickets');
    } else {
      return this.translateService.instant('ticket-manager.sales-record.set-ticket');
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayTime(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * getTicketType
   * @param type
   * @returns
   */
  getTicketType(type: string) {
    if (!type) {
      return;
    }
    switch (type) {
      case 'combo':
        return 'セット券';
      case 'ticket':
        return '企画券';
      case 'coupon':
        return '回数券';
      default:
        break;
    }
  }

  /**
   * getStatus
   * @param status
   * @returns
   */
  getStatus(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      case '購入中':
        return 'pending';
      case '購入済み':
        return 'bought';
      case '購入失敗':
        return 'failed';
      case '払戻済み':
        return 'refunded';
      case 'セッションタイムアウト':
        return 'sessionExpired';
      default:
        break;
    }
  }

  /**
   * getStatusForComboItem
   * @param status
   * @returns
   */
  getStatusForComboItem(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      case '未使用':
        return 'bought';
      case '有効化済み':
        return 'activated';
      case '使用済み':
        return 'used';
      default:
        break;
    }
  }
}

export interface itemObject {
  key: string;
  value: any;
}
